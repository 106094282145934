import React from "react"
import { Badge } from "react-bootstrap"

const GenreBadge = ({ genre }) => {
  return (
    <Badge variant="dark">
      <a href={ "/preset_packs/serum/"+ genre.slug }>
        {genre.name}
      </a>
    </Badge>
  )
}

export default GenreBadge
