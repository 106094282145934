import React from "react"
import { connect } from "react-redux"
import { Row, Col } from "react-bootstrap"

import ProductCard from "components/ProductCard"

const UpsellProducts = ({ upsell_products }) => {
  return (
    <React.Fragment>
      {upsell_products.length > 0 &&
        <div>
          <h4>Other Great Xfer Products</h4>
        </div>
      }
      <Row className="upsell-products">
        {upsell_products.map((upsell_product) => (
          <Col sm className="d-flex" key={upsell_product.id}>
            <ProductCard product={upsell_product} />
          </Col>
        ))}
      </Row>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  upsell_products: state.upsell_products
})


export default connect(mapStateToProps)(UpsellProducts)
