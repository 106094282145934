const cart_items = (state = [], action) => {
  switch (action.type) {
    case 'ADD_CART_ITEM':
      return [
        ...state,
        action.cart_item
      ]
    case 'REMOVE_CART_ITEM':
      return state.filter(cart_item => cart_item.id !== action.cart_item_id);
    case 'ALL_CART_ITEMS':
      return action.cart_items;
    default:
      return state
  }
}

export default cart_items
