import React from "react"
import { connect } from "react-redux"
import { Row, Col } from "react-bootstrap"
import PresetGroupCard from "components/PresetGroupCard"

const XferPicks = ({ pack_groups }) => {
  return (
    <React.Fragment>
      <Row>
        <Col>
          <h3>Xfer Picks</h3>
          <Row>
            {pack_groups.map((pack_group, i) => (
              <Col key={i} className="col-sm-6 col-lg-3 d-flex align-items-stretch">
                <PresetGroupCard pack_group={pack_group} />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>

      <hr />
      <br />

      <Row>
        <Col>
          <h3>Newest Preset Packs</h3>
        </Col>
      </Row>
    </React.Fragment>
  );
}

const mapGroupStateToProps = state => ({
  pack_groups: state.pack_groups
})

export default connect(mapGroupStateToProps)(XferPicks)
