const upsell_serum_presets = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_UPSELL_SERUM_PRESETS':
      return action.upsell_serum_presets;
    default:
      return state
  }
}

export default upsell_serum_presets
