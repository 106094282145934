import React from "react"
import { render } from "react-dom"
import { createStore, applyMiddleware } from "redux"
import { connect, Provider } from "react-redux"

import XferAudioPlayer from "components/XferAudioPlayer"
import CheckoutCart from "components/CheckoutCart"

import rootReducer from "reducers"
import thunk from "redux-thunk"
import axios from "axios"
import { fetchAllCartItems, fetchAllUpsells } from "actions/index"

const store = createStore(rootReducer, applyMiddleware(thunk))

store.dispatch(fetchAllCartItems());
store.dispatch(fetchAllUpsells());

class Checkout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Provider store={store}>
        <CheckoutCart
          userSignedIn={this.props.userSignedIn}
          stripeKey={this.props.stripeKey}
          checkoutImage={this.props.checkoutImage}
          userEmail={this.props.userEmail}
          authenticityToken={this.props.authenticityToken}
          serumOrSerumPresets={this.props.serumOrSerumPresets}
          userId={this.props.userId}
        />

        <XferAudioPlayer />
      </Provider>
    )
  }
}

export default Checkout;
