const upsell_products = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_UPSELL_PRODUCTS':
      return action.upsell_products;
    default:
      return state
  }
}

export default upsell_products
