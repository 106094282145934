import React from "react"
import ReactDOM from "react-dom"

const ShoppingCartContainer = ({ children }) => {
  return ReactDOM.createPortal(
    children,
    document.getElementById('checkout-nav')
  );
};

export default ShoppingCartContainer
