import React from "react"
import { Button } from "react-bootstrap"
import { connect } from "react-redux"
import { removeCartItemFromCart } from "../actions"

class CartItem extends React.Component {
  render() {
    return (
      <tr>
        <td width="75" className="d-none d-sm-table-cell">
          <img className="cart-item-img" width="75" src={this.props.cart_item.image} />
        </td>
        <td>
          <h4 className="item-name">{this.props.cart_item.name}</h4>
          <span>{this.props.cart_item.short_description}</span>
        </td>
        <td width="75">
          <div className="item-price text-center">
            <h5>${this.props.cart_item.price}</h5>
          </div>

          <div className="d-flex justify-content-center">
            <a
              className="remove-button"
              variant=""
              onClick={() => this.props.dispatch(removeCartItemFromCart(this.props.cart_item.id))}
              >
              <i className="fa fa-minus"></i> Remove
            </a>
          </div>
        </td>
      </tr>
    )
  }
}

const mapStateToProps = state => ({
  cart_items: state.cart_items
})

export default connect(mapStateToProps)(CartItem);
