import React from "react"
import { connect } from "react-redux"

import PlayButton from "components/PlayButton"
import PresetModal from "components/PresetModal"
import GenreBadges from "components/GenreBadges"
import CartPlayButtons from "components/CartPlayButtons"

import { addCartItemRequest, cartItemIds } from "../actions"

class PresetCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalShow: false,
    };
  };

  addPackToCart = (cart_item) => {
    this.props.dispatch(addCartItemRequest(cart_item.id));
  }

  render () {
    let modalClose = () => this.setState({ modalShow: false });
    let inCart = this.props.cart_items_ids.includes(this.props.preset_pack.id);
    let image = (this.props.mega) ? (this.props.preset_pack.images.full) : (this.props.preset_pack.images.mid);

    return (
      <React.Fragment>
        <div className={this.props.cardSize}>
          <img src={image}
            style={{cursor:'pointer'}}
            onClick={() => this.setState({ modalShow: true })}
            className="card-img-top"
          />

          {this.props.preset_pack.featured &&
            <div className="ribbon"><span>Featured</span></div>
          }
          {this.props.preset_pack.is_new &&
            <div className="ribbon"><span>New</span></div>
          }

          <CartPlayButtons addToCart={this.addPackToCart} preset_pack={this.props.preset_pack} inCart={inCart} />

          <div className="card-body">
            <span className="card-title" style={{cursor:'pointer'}} onClick={() => this.setState({ modalShow: true })}>
              {this.props.preset_pack.name}
            </span>

            <br />

            <span>
              by <a href={"/preset_packs/manufacturers/"+this.props.preset_pack.manufacturer_slug}>
              {this.props.preset_pack.manufacturer_name}
              </a>
            </span>

            {this.props.mega &&
              <React.Fragment>
                <br />
                <hr />

                <div>{this.props.preset_pack.description}</div>
              </React.Fragment>
            }
          </div>
          <div className="card-footer">
            <GenreBadges genres={this.props.preset_pack.genres} />
          </div>
        </div>

        <PresetModal
          preset_pack={this.props.preset_pack}
          show={this.state.modalShow}
          onHide={modalClose}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  cart_items_ids: cartItemIds(state.cart_items)
})

export default connect(mapStateToProps)(PresetCard)
