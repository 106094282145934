const cart_errors = (state = [], action) => {
  switch (action.type) {
    case 'ADD_CART_ERROR':
      return [
        ...state,
        {
          message: action.message
        }
      ]
    case 'REMOVE_CART_ERROR':
      return state.filter(cart_error => cart_error.id !== action.cart_error_id);
    default:
      return state
  }
}

export default cart_errors
