import React from "react"

import PlayButton from "components/PlayButton"
import CartButton from "components/CartButton"
import { Button, ButtonGroup } from "react-bootstrap"

const CartPlayButtons = ({ addToCart, preset_pack, inCart }) => {
  return (
    <ButtonGroup className="card-actions modal-actions">
      <PlayButton preset_pack={preset_pack} />

      <CartButton
        product={preset_pack}
        inCart={inCart}
        addToCart={addToCart}
      />

    </ButtonGroup>
  )
}

export default CartPlayButtons
