import React from "react"
import { Carousel } from "react-bootstrap"

class PresetGroupCarousel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalShow: false,
    };
  };

  render() {
    return (
      <Carousel>
        {this.props.pack_group.preset_packs.map((preset_pack, i) => (
          <Carousel.Item key={i}>
            <img src={preset_pack.image}
              style={{cursor:'pointer'}}
              onClick={() => this.setState({ modalShow: true })}
              className="card-img-top"
            />
            <Carousel.Caption>
              <h3></h3>
              <p></p>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
  )}
}

export default PresetGroupCarousel
