import React from "react"
import { connect } from "react-redux"

import PlayButton from "components/PlayButton"
import PresetModal from "components/PresetModal"
import CartButton from "components/CartButton"

import { addCartItemRequest, cartItemIds } from "../actions"

class ProductCard extends React.Component {
  constructor(props) {
    super(props);
  };

  addProductToCart = (cart_item) => {
    this.props.dispatch(addCartItemRequest(cart_item.id));
  }

  render () {
    let inCart = this.props.cart_items_ids.includes(this.props.product.id);

    return (
      <React.Fragment>
        <div className="card h100 w-100 upsell-product-card" style={{ backgroundImage: `url(${this.props.product.large_image})` }}>
          <div className="card-title">
            <span>{this.props.product.name}</span>
          </div>

          <CartButton addToCart={this.addProductToCart} product={this.props.product} inCart={inCart} />

          <div className="card-body">

            <p>
              {this.props.product.description}
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  cart_items_ids: cartItemIds(state.cart_items)
})

export default connect(mapStateToProps)(ProductCard)
