import React from "react"
import { render } from "react-dom"
import { createStore, applyMiddleware } from "redux"
import { connect, Provider } from "react-redux"

import XferAudioPlayer from "components/XferAudioPlayer"
import PresetTable from "components/PresetTable"
import ShoppingCartContainer from "components/ShoppingCartContainer"
import ShoppingCart from "components/ShoppingCart"

import rootReducer from "reducers"
import thunk from "redux-thunk"
import axios from "axios"
import { fetchGenrePacks, fetchAllCartItems } from "actions/index"

const store = createStore(rootReducer, applyMiddleware(thunk))

store.dispatch(fetchAllCartItems());

class GenrePresets extends React.Component {
  componentDidMount() {
    store.dispatch(fetchGenrePacks(this.props.genre.slug));
  };

  constructor(props) {
    super(props);
    this.state = {};
    document.getElementById('checkout-nav').innerHTML = '';
  };

  render() {
    return (
      <Provider store={store}>
        <h1>{this.props.genre.name} Packs</h1>
        <PresetTable />

        <ShoppingCartContainer>
          <ShoppingCart />
        </ShoppingCartContainer>
        <XferAudioPlayer />
      </Provider>
    )
  }
}

export default GenrePresets;
