import React from "react"
import { connect } from "react-redux"
import { Table } from "react-bootstrap"

import PresetRow from "components/PresetRow"

class PresetTable extends React.Component {
  constructor(props) {
    super(props);
  };

  render() {
    return (
      <Table striped bordered hover variant="dark" className="preset-table">
        <tbody>
          {this.props.packs.map((preset_pack) => (
            <PresetRow key={preset_pack.id} preset_pack={preset_pack}/>
          ))}
        </tbody>
      </Table>
  )}
}

const mapStateToProps = state => ({
  packs: state.packs
})

export default connect(mapStateToProps)(PresetTable)
