import 'core-js'
import React from "react"
import { render } from "react-dom"
import axios from "axios"

class SerumDownload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {step: 1};
  }

  headerMessage(message) {
    return (
      <div className="row">
        <div className="col-md text-center">
          <h3>{message}</h3>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.step == 1) {
      return (
        <div>
          {this.headerMessage("Which Type of Serum?")}
          <div className="row">
            <button className="col-md serum-dl-button text-center"
              onClick={() => this.setState({step: 2, type: 'Serum'})}>
              <h2>Serum</h2>
              <p>The full version of Serum</p>
            </button>
            <div className="col-md serum-dl-button text-center"
              onClick={() => this.setState({step: 2, type: 'SerumFX'})}>
              <h2>SerumFX</h2>
              <p>The optional/bonus 'FX' version of Serum</p>
              <p>(for processing signals through the Serum effects rack)</p>
            </div>
          </div>
        </div>
      );
    } else if (this.state.step == 2) {
      return (
        <div>
          {this.headerMessage("Which OS?")}
          <div className="row">
            <button className="col-md serum-dl-button text-center"
              onClick={() => this.setState({step: 3, os: "osx"})}>
              <h2>OS X <i className="fa fa-apple"></i></h2>
              <p>Macintosh/OS X version</p>
            </button>

            <div className="col-md serum-dl-button text-center"
              onClick={() => this.setState({step: 3, os: "windows"})}>
              <h2>Windows <i className="fa fa-windows"></i></h2>
              <p>Windows OS Serum Version</p>
            </div>
          </div>

          <div className="text-center">
            <a href="#" onClick={() => this.setState({step: this.state.step-1})}>back</a>
          </div>
        </div>
      );
    } else if (this.state.step == 3 && this.state.type == "SerumFX" && this.state.os == "osx") {
      return (
        <div>
          <div className="row">
            <div className="col-md text-center">
              <h2>SerumFX Installer for OS X <i className="fa fa-apple"></i></h2>
              <p>{this.props.serum_fx_osx.version_number}</p>
              <br />

              <a href={this.props.serum_fx_osx_link}
                className="btn btn-primary btn-lg download-button">
                Download <i className="fa fa-download"></i>
              </a>
              <br />
              <br />

              <h4>Release Notes</h4>
              <div dangerouslySetInnerHTML={ { __html: this.props.serum_fx_osx.release_notes} } />
              <br />

              <div className="text-center">
                <a href="#" onClick={() => this.setState({step: this.state.step-1})}>back</a>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (this.state.step == 3 && this.state.type == "SerumFX" && this.state.os == "windows") {
      return (
        <div>
          <div className="row">
            <div className="col-md text-center">
              <h2>SerumFX Installer for Windows <i className="fa fa-windows"></i></h2>
              <p>{this.props.serum_fx_windows.version_number}</p>
              <br />

              <a href={this.props.serum_fx_windows_link}
                className="btn btn-primary btn-lg download-button">
                Download <i className="fa fa-download"></i>
              </a>
              <br />
              <br />

              <h4>Release Notes</h4>
              <div dangerouslySetInnerHTML={ { __html: this.props.serum_fx_windows.release_notes} } />
              <br />

              <div className="text-center">
                <a href="#" onClick={() => this.setState({step: this.state.step-1})}>back</a>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (this.state.step == 3 && this.state.type == "Serum") {
      return (
        <div>
          {this.headerMessage("Full Installer or Update?")}

          <div className="row">
            <button className="col-md serum-dl-button text-center"
              onClick={() => this.setState({step: 4, update: false})}>
              <h2>Full Serum Installer</h2>
              <p>The full version of Serum with all content</p>
            </button>

            <div className="col-md serum-dl-button text-center"
              onClick={() => this.setState({step: 4, update: true})}>
              <h2>Serum Update</h2>
              <p>Updates an existing Serum install, if you already have Serum or the demo installed, this installer will save you time.</p>
            </div>
          </div>

          <div className="text-center">
            <a href="#" onClick={() => this.setState({step: this.state.step-1})}>back</a>
          </div>
        </div>
      );
    } else if (this.state.step == 4 && this.state.update == false && this.state.os == "windows") {
      return (
        <div>
          <div className="row">
            <div className="col-md text-center">
              <h2>Serum Installer for Windows <i className="fa fa-windows"></i></h2>
              <p>{this.props.serum_installer_windows.version_number}</p>
              <br />

              <a href={this.props.serum_installer_windows_link}
                className="btn btn-primary btn-lg download-button">
                Download <i className="fa fa-download"></i>
              </a>
              <br />
              <br />

              <h4>Release Notes</h4>
              <div dangerouslySetInnerHTML={ { __html: this.props.serum_installer_windows.release_notes} } />
              <br />

              <div className="text-center">
                <a href="#" onClick={() => this.setState({step: this.state.step-1})}>back</a>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (this.state.step == 4 && this.state.update == false && this.state.os == "osx") {
      return (
        <div>
          <div className="row">
            <div className="col-md text-center">
              <h2>Serum Installer for OS X <i className="fa fa-apple"></i></h2>
              <p>{this.props.serum_installer_osx.version_number}</p>
              <br />

              <a href={this.props.serum_installer_osx_link}
                className="btn btn-primary btn-lg download-button">
                Download <i className="fa fa-download"></i>
              </a>
              <br />
              <br />

              <h4>Release Notes</h4>
              <div dangerouslySetInnerHTML={ { __html: this.props.serum_installer_osx.release_notes} } />
              <br />

              <div className="text-center">
                <a href="#" onClick={() => this.setState({step: this.state.step-1})}>back</a>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (this.state.step == 4 && this.state.update == true && this.state.os == "osx") {
      return (
        <div>
          <div className="row">
            <div className="col-md text-center">
              <h2>Serum Updater for OS X <i className="fa fa-apple"></i></h2>
              <p>{this.props.serum_update_osx.version_number}</p>
              <br />

              <a href={this.props.serum_update_osx_link}
                className="btn btn-primary btn-lg download-button">
                Download <i className="fa fa-download"></i>
              </a>
              <br />
              <br />

              <h4>Release Notes</h4>
              <div dangerouslySetInnerHTML={ { __html: this.props.serum_update_osx.release_notes} } />
              <br />

              <div className="text-center">
                <a href="#" onClick={() => this.setState({step: this.state.step-1})}>back</a>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (this.state.step == 4 && this.state.update == true && this.state.os == "windows") {
      return (
        <div>
          <div className="row">
            <div className="col-md text-center">
              <h2>Serum Updater for Windows <i className="fa fa-windows"></i></h2>
              <p>{this.props.serum_update_windows.version_number}</p>
              <br />

              <a href={this.props.serum_update_windows_link}
                className="btn btn-primary btn-lg download-button">
                Download <i className="fa fa-download"></i>
              </a>
              <br />
              <br />

              <h4>Release Notes</h4>
              <div dangerouslySetInnerHTML={ { __html: this.props.serum_update_windows.release_notes} } />
              <br />

              <div className="text-center">
                <a href="#" onClick={() => this.setState({step: this.state.step-1})}>back</a>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>Sorry, an error occured. Please refresh this page</div>
      );
    }
  }
}

export default SerumDownload;
