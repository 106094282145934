import React from "react"
import { connect } from "react-redux"
import { Row, Col } from "react-bootstrap"

import PresetCard from "components/PresetCard"

const SerumUpsells = ({ upsell_serum_presets }) => {
  return (
    <React.Fragment>
      {upsell_serum_presets.length > 0 &&
        <h4>Serum Presets</h4>
      }
      <Row className="serum-upsells">
        {upsell_serum_presets.map((upsell_preset) => (
          <Col sm={4} key={upsell_preset.id} className="">
            <PresetCard preset_pack={upsell_preset} cardSize='card h100 w-100' />
          </Col>
        ))}
      </Row>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  upsell_serum_presets: state.upsell_serum_presets
})


export default connect(mapStateToProps)(SerumUpsells)
