import React from "react"
import { connect } from "react-redux"
import { Row, Col } from "react-bootstrap"

import LoadingSpinner from "components/LoadingSpinner"
import PresetCard from "components/PresetCard"

const FeaturedPacks = ({ featured_packs }) => {
  const first_pack = featured_packs.shift();
  const row_1 = featured_packs.slice(0, 2)
  const row_2 = featured_packs.slice(2, 4)

  return (
    <React.Fragment>
      { first_pack == null &&
        <LoadingSpinner message="Loading Packs..." />
      }

      <Row>
        <Col md={12} lg={6} className="d-flex align-items-stretch">
          {first_pack != null &&
            <PresetCard cardSize="card h100 w-100 mega-card" mega={true} preset_pack={first_pack} />
          }
        </Col>

        <Col md={12} lg={6}>
          <Row>
            {row_1.map((preset_pack) => (
              <div key={preset_pack.id} className="col-sm-6 d-flex">
                <PresetCard key={preset_pack.id} preset_pack={preset_pack} cardSize='card medium-card h50 w-100' />
              </div>
            ))}
          </Row>

          <Row>
            {row_2.map((preset_pack) => (
              <Col sm={6} key={preset_pack.id} className="d-flex align-items-stretch">
                <PresetCard key={preset_pack.id} preset_pack={preset_pack} cardSize='card medium-card h50 w-100' />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  featured_packs: state.featured_packs
})

export default connect(mapStateToProps)(FeaturedPacks)
