import React from "react"
import ReactLoading from "react-loading"

const LoadingSpinner = ({ message }) => {
  return (
    <div className="d-flex justify-content-center">
      <ReactLoading type='spin' color='white' height={'50px'} width={'50px'} />
      <p style={{marginLeft: '20px', marginTop: '15px'}}>
        {message}
      </p>
    </div>
)};

export default LoadingSpinner;
