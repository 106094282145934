import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { NavDropdown, Row, Col, Button } from "react-bootstrap"
import { removeCartItemFromCart, getCartTotal } from "../actions"

const ShoppingCart = ({ dispatch, cart_items, cart_total }) => {
  return(
    <NavDropdown
      title={`Cart (${cart_total})`}
      onSelect={(e) => { e.preventDefault() }}
      className="cart-nav-dropdown">
      {cart_items.map((cart_item) => (
        <NavDropdown.Item className="cart-drop-item" key={cart_item.id}>
          <Row>
            <Col sm={2} className="cart-img-col">
              <img className="cart-item-img" width="75" src={cart_item.image} />
            </Col>
            <Col sm={7} className="cart-item-name align-self-center">
              <span className="cart-item-name-title">{cart_item.name}</span>
              <br />
              <span>{cart_item.short_description}</span>
            </Col>
            <Col sm={3} className="align-self-center">
              <h5>${cart_item.price}</h5>
              <a className="remove-button"
                onClick={() => dispatch(removeCartItemFromCart(cart_item.id))}>
                <i className="fa fa-minus"></i> Remove
              </a>
            </Col>
          </Row>
        </NavDropdown.Item>
      ))}

      <NavDropdown.Item href="/shopping_cart/checkout" className="checkout-item">
        <Button variant="primary" className="align-self-center checkout-button btn-block">
          <i className="fa fa-shopping-cart"></i>
          Checkout {cart_total}
        </Button>
      </NavDropdown.Item>
    </NavDropdown>
  )
}

const mapStateToProps = state => ({
  cart_items: state.cart_items,
  cart_total: getCartTotal(state.cart_items)
})


export default connect(mapStateToProps)(ShoppingCart)
