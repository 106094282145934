import React from "react"
import { connect } from "react-redux"
import { Row, Col } from "react-bootstrap"

import PresetModal from "components/PresetModal"
import GenreBadges from "components/GenreBadges"
import CartPlayButtons from "components/CartPlayButtons"
import { addCartItemRequest, cartItemIds } from "../actions"

class PresetRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalShow: false,
    };
  };

  addPackToCart = (cart_item) => {
    this.props.dispatch(addCartItemRequest(cart_item.id));
  }

  render() {
    let modalClose = () => this.setState({ modalShow: false });
    let inCart = this.props.cart_items_ids.includes(this.props.preset_pack.id);

    return (
      <React.Fragment key={this.props.preset_pack.id}>
        <tr>
          <td className="d-none d-sm-table-cell">
            <img src={this.props.preset_pack.images.thumb}
              width="75"
              style={{cursor:'pointer'}}
              onClick={() => this.setState({ modalShow: true })}
            />
          </td>
          <td className="d-none d-sm-table-cell">
            <h5
              style={{cursor:'pointer'}}
              onClick={() => this.setState({ modalShow: true })}>
              {this.props.preset_pack.name}
            </h5>
          </td>
          <td className="d-none d-sm-table-cell" style={{width: "16.66%"}}><GenreBadges genres={this.props.preset_pack.genres} /></td>
          <td className="d-none d-sm-table-cell">
            <a href={"/preset_packs/manufacturers/" + this.props.preset_pack.manufacturer_slug}>
              {this.props.preset_pack.manufacturer_name}
            </a>
          </td>
          <td>
            <div className="d-block d-sm-none">
              <Row>
                <Col xs={5}>
                  <img src={this.props.preset_pack.images.thumb} className="mobile-preset-image" />
                </Col>
                <Col>
                  <strong>{this.props.preset_pack.name}</strong>
                  <br />
                  by {this.props.preset_pack.manufacturer_name}
                </Col>
              </Row>
            </div>

            <div className="d-flex justify-content-center align-self-center preset-table-actions">
              <CartPlayButtons addToCart={this.addPackToCart} preset_pack={this.props.preset_pack} inCart={inCart} />
            </div>
          </td>
        </tr>

        <PresetModal
          preset_pack={this.props.preset_pack}
          show={this.state.modalShow}
          onHide={modalClose}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  cart_items_ids: cartItemIds(state.cart_items)
})

export default connect(mapStateToProps)(PresetRow)
