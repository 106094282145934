import React from "react"
import GenreBadge from "components/GenreBadge"

const GenreBadges = ({ genres }) => {
  return (
    <div className="genres">
      {genres.map((genre) => (
      <GenreBadge key={genre.id} genre={genre} />
      ))}
    </div>
  )
}

export default GenreBadges
