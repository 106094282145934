import React from "react"

import { Button } from "react-bootstrap"

const CartButton = ({ addToCart, product, inCart }) => {
  return (
    <React.Fragment>
      {(product.owned || inCart) ? (
        <Button className="preset-card-cart" variant="secondary">
          <i className="fa fa-check"></i>
          <span className="card-button">
            {product.owned ? ('Owned') : ('In Cart')}
          </span>
        </Button>
      ) : (
        <Button onClick={(e) => addToCart(product, e)} className="preset-card-cart" variant="secondary">
          <i className="fa fa-cart-plus"></i>
          <span className="price card-button">
            {product.price}
          </span>
        </Button>
      )}
    </React.Fragment>
  )
}

export default CartButton
