import React from "react";
import { connect } from "react-redux";
import { Elements, StripeProvider } from "react-stripe-elements";
import StripeCheckout from "./StripeCheckout";

import { getCartTotal } from "../actions";
import { Button, Col } from "react-bootstrap";

class PaymentOptions extends React.Component {
  constructor() {
    super();
    this.state = {
      stripeHidden: true,
    };
  }
  toggleHidden() {
    this.setState({
      stripeHidden: !this.state.stripeHidden,
    });
  }
  render() {
    return (
      <React.Fragment>
        <Col sm className="d-flex justify-content-center align-self-center">
          {this.state.stripeHidden && (
            <Button variant="primary" onClick={this.toggleHidden.bind(this)}>
              Pay {this.props.cart_total} USD with Stripe
              <br />
              <i className="fa fa-2x fa-cc-visa"></i>
              <i className="fa fa-2x fa-cc-mastercard"></i>
              <i className="fa fa-2x fa-cc-amex"></i>
            </Button>
          )}

          {!this.state.stripeHidden && (
            <StripeProvider apiKey={this.props.stripeKey}>
              <Elements>
                <StripeCheckout />
              </Elements>
            </StripeProvider>
          )}

          {this.state.stripeHidden && (
            <Col sm={5} className="offset-sm-1 d-flex justify-content-center align-self-center">
              <a href="/shopping_cart/paypal_checkout">
                <button className="btn btn-primary btn-block">
                  Pay {this.props.cart_total} USD with Paypal
                  <br />
                  <i className="fa fa-2x fa-paypal"></i>
                </button>
              </a>
            </Col>
          )}
        </Col>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  cart_total: getCartTotal(state.cart_items),
});

export default connect(mapStateToProps)(PaymentOptions);
