const packs = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_PACK':
      return action.packs;
    default:
      return state
  }
}

export default packs
