import React from "react"
import { connect } from "react-redux"

import PlayButton from "components/PlayButton"
import PresetGroupModal from "components/PresetGroupModal"
import GenreBadge from "components/GenreBadge"
import PresetGroupCarousel from "components/PresetGroupCarousel"
import { Card, Button, ButtonGroup, Badge } from "react-bootstrap";
import { addPreview } from "../actions"

class PresetGroupCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalShow: false,
    };
  };

  render () {
    let modalClose = () => this.setState({ modalShow: false });

    return (
      <React.Fragment>
        <Card key={this.props.pack_group.id} className="card h100 w-100">
          <PresetGroupCarousel
            pack_group={this.props.pack_group}
            modal_show={this.state.modalShow}
          />

          <Card.Body className="bg-dark">
            <span className="card-title">
              {this.props.pack_group.name}
              <Badge variant="light">{this.props.pack_group.preset_packs.length} packs</Badge>
            </span>
            <Card.Text>
              {this.props.pack_group.description}
            </Card.Text>
          </Card.Body>

          <Card.Footer>
            {this.props.pack_group.genres.map((genre, i) => (
              <GenreBadge key={genre.id} genre={genre} />
            ))}
          </Card.Footer>
        </Card>
        <PresetGroupModal
          pack_group={this.props.pack_group}
          show={this.state.modalShow}
          onHide={modalClose}
        />
      </React.Fragment>
    );
  }
}

export default connect()(PresetGroupCard)
