const pack_groups = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_PACK_GROUPS':
      return action.pack_groups;
    default:
      return state
  }
}

export default pack_groups
