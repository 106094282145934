import React from "react"
import { connect } from "react-redux"
import StripeCheckout from "react-stripe-checkout"
import { cartItemNames, getCartTotal, addCartError } from "../actions"

class StripeButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onToken = (token) => {
    fetch(`/orders.json?user_id=${encodeURIComponent(this.props.userId)}`, {
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': this.props.authenticityToken,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(token),
    }).then(response => {
      response.json().then(data => {
        if (data.success) {
          window.location.replace("/my_account");
        } else {
          this.props.dispatch(addCartError(data));
        }
      });
    });
  }

  render() {
    return (
      <StripeCheckout
        className="justify-content-center align-self-center"
        token={this.onToken}
        stripeKey={this.props.stripeKey}
        amount={this.props.cart_total * 100}
        panelLabel="Pay"
        image={this.props.checkoutImage}
        name="Xfer Records"
        description={this.props.cart_item_names}
        locale="auto"
        email={this.props.userEmail}
        zipCode={true}
        allowRememberMe={false}
        billingAddress={true}>
        <button className="btn btn-primary btn-block">
          Pay {this.props.cart_total} with Stripe
          <br />
          <i className='fa fa-2x fa-cc-visa'></i>
          <i className='fa fa-2x fa-cc-mastercard'></i>
          <i className='fa fa-2x fa-cc-amex'></i>
        </button>
      </StripeCheckout>
    )
  }
}

const mapStateToProps = state => ({
  cart_item_names: cartItemNames(state.cart_items),
  cart_errors: state.cart_errors,
  cart_total: getCartTotal(state.cart_items)
})

export default connect(mapStateToProps)(StripeButton)
