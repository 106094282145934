import axios from 'axios';

// previews
export const addPreview = (preset_pack) => ({
  type: 'ADD_PREVIEW',
  id: preset_pack.id,
  name: preset_pack.name,
  image: preset_pack.images.mid,
  preview: preset_pack.preview,
  manufacturer_name: preset_pack.manufacturer_name
})

export const allPreviews = (previews) => {
  return {
    type: 'ALL_PREVIEWS',
    previews
  }
};

// packs
const apiUrl = '/';

export const fetchFeaturedPacks = (featured_packs) => {
  return {
    type: 'FETCH_FEATURED_PACK',
    featured_packs
  }
};

export const fetchPacks = (packs) => {
  return {
    type: 'FETCH_PACK',
    packs
  }
};

export const fetchAllPacks = () => {
  return (dispatch) => {
    return axios.get(apiUrl + 'preset_packs.json')
      .then(response => {
        dispatch(fetchPacks(response.data.preset_packs))
        dispatch(fetchFeaturedPacks(response.data.featured_packs))
      })
      .catch(error => {
        throw(error);
      });
  };
};

export const fetchGenrePacks = (genre_slug) => {
  return (dispatch) => {
    return axios.get(apiUrl + 'preset_packs/serum/'+genre_slug+'.json')
      .then(response => {
        dispatch(fetchPacks(response.data.preset_packs))
      })
      .catch(error => {
        throw(error);
      });
  };
}

export const fetchManufacturerPacks = (manufacturer_slug) => {
  return (dispatch) => {
    return axios.get(apiUrl + 'preset_packs/manufacturers/'+manufacturer_slug+'.json')
      .then(response => {
        dispatch(fetchPacks(response.data.preset_packs))
      })
      .catch(error => {
        throw(error);
      });
  };
}


// pack groups
export const fetchPackGroups = (pack_groups) => {
  return {
    type: 'FETCH_PACK_GROUPS',
    pack_groups
  }
};

export const fetchAllGroupPacks = () => {;
  return (dispatch) => {
    return axios.get(apiUrl + 'preset_pack_groups/picks.json')
      .then(response => {
        dispatch(fetchPackGroups(response.data.preset_pack_groups))
      })
      .catch(error => {
        throw(error);
      });
  };
};

// cart items
export const fetchCartItems = (cart_items) => {
  return {
    type: 'ALL_CART_ITEMS',
    cart_items
  }
};

export const fetchAllCartItems = () => {;
  return (dispatch) => {
    return axios.get(apiUrl + 'shopping_cart/items.json')
      .then(response => {
        dispatch(fetchCartItems(response.data.items))
      })
      .catch(error => {
        throw(error);
      });
  };
};

export const addCartItem = (cart_item) => {
  return {
    type: 'ADD_CART_ITEM',
    cart_item
  }
}

export const addCartItemRequest = (cart_item_id) => {;
  return (dispatch) => {
    return axios.get(apiUrl + 'shopping_cart/add.json?product_id=' + cart_item_id)
      .then(response => {
        if (response.data.success) {
          dispatch(addCartItem(response.data.new_cart_item))
        } else {
          alert(response.data.message);
        }
      })
      .catch(error => {
        alert('Sorry, There was an error adding this item to your cart!')
        throw(error);
      });
  };
};

export const removeCartItem = (cart_item_id) => {
  return {
    type: 'REMOVE_CART_ITEM',
    cart_item_id
  };
}

export const removeCartItemFromCart = (cart_item_id) => {;
  return (dispatch) => {
    return axios.get(apiUrl + 'shopping_cart/remove.json?product_id=' + cart_item_id)
      .then(response => {
        dispatch(removeCartItem(cart_item_id))
      })
      .catch(error => {
        throw(error);
      });

  }
}

export const getCartTotal = items => {
  const amount = (items.reduce((total, item) => total + parseFloat(item.price), 0)).toFixed(2)
  return "$" + amount
}

export const cartItemIds = items => {
  const item_ids = items.map(item => item.id);
  return item_ids;
}

export const cartItemNames = items => {
  const names = items.map(item => item.name).join(', ');
  return names
}

// cart errors
export const addCartError = (cart_error) => ({
  type: 'ADD_CART_ERROR',
  success: cart_error.success,
  message: cart_error.message
})

export const removeCartError = (cart_error_id) => {
  return {
    type: 'REMOVE_CART_ERROR',
    cart_error_id
  };
}


// Cart upsells
export const fetchUpsellProducts = (upsell_products) => {
  return {
    type: 'FETCH_UPSELL_PRODUCTS',
    upsell_products
  }
};

export const fetchUpsellSerumPresets = (upsell_serum_presets) => {
  return {
    type: 'FETCH_UPSELL_SERUM_PRESETS',
    upsell_serum_presets
  }
};

export const fetchAllUpsells = () => {;
  return (dispatch) => {
    return axios.get(apiUrl + 'shopping_cart/upsells.json')
      .then(response => {
        dispatch(fetchUpsellProducts(response.data.upsell_products))
        dispatch(fetchUpsellSerumPresets(response.data.upsell_serum_presets))
      })
      .catch(error => {
        throw(error);
      });
  };
};
