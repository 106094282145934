import { combineReducers } from 'redux'
import previews from './previews'
import packs from './packs'
import featured_packs from './featuredPacks'
import pack_groups from './packGroups'
import cart_items from './cartItems'
import cart_errors from './cartErrors'
import upsell_products from './upsellProducts'
import upsell_serum_presets from './upsellSerumPresets'

export default combineReducers({
  previews,
  packs,
  featured_packs,
  pack_groups,
  cart_items,
  cart_errors,
  upsell_products,
  upsell_serum_presets
})
