const featured_packs = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_FEATURED_PACK':
      return action.featured_packs;
    default:
      return state
  }
}

export default featured_packs
