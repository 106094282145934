import 'core-js'
import React from "react"
import { render } from "react-dom"
import { createStore, applyMiddleware } from "redux"
import { connect, Provider } from "react-redux"
import { BrowserView, isBrowser } from "react-device-detect"

import FeaturedPacks from "components/FeaturedPacks"
import XferAudioPlayer from "components/XferAudioPlayer"
import PresetTable from "components/PresetTable"
import ShoppingCartContainer from "components/ShoppingCartContainer"
import ShoppingCart from "components/ShoppingCart"

import rootReducer from "reducers"
import thunk from "redux-thunk"
import axios from "axios"
import { fetchAllPacks, fetchAllCartItems } from "actions/index"

const store = createStore(rootReducer, applyMiddleware(thunk))

store.dispatch(fetchAllPacks());
store.dispatch(fetchAllCartItems());

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    if (isBrowser) {
      // Don't show dropdown cart on mobile
      document.getElementById('checkout-nav').innerHTML = '';
    }
  }

  render() {
    return (
      <Provider store={store}>
        <FeaturedPacks />
        <PresetTable />

        <BrowserView>
          <ShoppingCartContainer>
            <ShoppingCart />
          </ShoppingCartContainer>
        </BrowserView>

        <XferAudioPlayer />
      </Provider>
    )
  }
}

export default Main;
