import React from "react"
import { connect } from "react-redux"
import { addPreview } from '../actions'
import Button from "react-bootstrap/Button";

const PlayButton = ({ dispatch, preset_pack }) => {
  return (
    <Button className="preset-card-play" variant="secondary"
      onClick={() => dispatch(addPreview(preset_pack))}>
      <i className="fa fa-play"></i>
    </Button>
  )
}

const mapStateToProps = state => ({
  previews: state.previews
})

export default connect(mapStateToProps)(PlayButton)
