const previews = (state = [], action) => {
  switch (action.type) {
    case 'ADD_PREVIEW':
      return [
        ...state,
        {
          id: action.id,
          name: action.name,
          preview: action.preview,
          image: action.image,
          manufacturer_name: action.manufacturer_name
        }
      ]
    case 'POP_PREVIEW':
      return [
        ...state,
        state.filter(element => element.id == action.id)
      ]
    case 'ALL_PREVIEWS':
      return action.previews;
    default:
      return state
  }
}

export default previews
