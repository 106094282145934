import React from "react"
import { Modal, Button, ButtonGroup, Row, Col } from "react-bootstrap"
import ReactPlayer from "react-player"

class PresetGroupModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render () {
    return (
      <Modal {...this.props} >
        <Modal.Body>
          {this.props.pack_group.name}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default PresetGroupModal
