import React, { Component } from "react"
import { connect } from "react-redux"
import { CardNumberElement, CardExpiryElement, CardCVCElement, injectStripe } from "react-stripe-elements"
import { Row, Col } from "react-bootstrap"

import { addCartError } from "../actions"

class StripeCheckout extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.stripeTokenHandler = this.stripeTokenHandler.bind(this);
    this.state = {
      disableSubmit: false
    }
  }

  stripeTokenHandler(token) {
    // Insert the token ID into the form so it gets submitted to the server
    var form = document.getElementById('payment-form');
    var hiddenInput = document.createElement('input');
    hiddenInput.setAttribute('type', 'hidden');
    hiddenInput.setAttribute('name', 'stripeToken');
    hiddenInput.setAttribute('value', token.id);
    form.appendChild(hiddenInput);

    // Submit the form
    form.submit();
  }

  submit = (ev) => {
    ev.preventDefault();
    this.setState({disableSubmit: true});
    var name = document.getElementById('CardName').value;
    this.props.stripe.createToken({ name: name }).then((result) => {
      if (result.error) {
        this.props.dispatch(addCartError({
          success: false,
          message: result.error.message
        }));
        this.setState({disableSubmit: false});
      } else {
        this.stripeTokenHandler(result.token);
      }
    });
  }


  render() {
    return (
      <div className="stripe-checkout">
          <form action="/orders" method="post" id="payment-form">
            <div>
              <Row>
                <Col md={{offset: 2, span: 8}}>
                  <strong>Enter your Credit or debit card details</strong>
                </Col>
              </Row>

              <Row>
                <Col md={{offset: 2, span: 8}}>
                  <input id="CardName" name="name" type="text" placeholder="Your Name" required style={{base: {color: '#fff', fontSize: '18px'}}} className="form-control" />
                </Col>
              </Row>

              <Row>
                <Col md={{offset: 2, span: 8}}>
                  <label>Card Number</label>
                  <CardNumberElement style={{base: {color: '#fff', fontSize: '18px'}}} className="form-control" />
                </Col>
              </Row>

              <Row>
                <Col md={{offset: 2, span: 4}}>
                <label>Card Expiry Date</label>
                  <CardExpiryElement style={{base: {color: '#fff', fontSize: '18px'}}} className="form-control" />
                </Col>

                <Col md={{span: 4}}>
                <label>Card CVC</label>
                  <CardCVCElement style={{base: {color: '#fff', fontSize: '18px'}}} className="form-control" />
                </Col>
              </Row>

              <Row>
                <Col md={{offset: 2, span: 8}}>
                  <button
                    className="btn btn-block btn-primary"
                    disabled={this.state.disableSubmit}
                    onClick={this.submit}>
                    Checkout
                  </button>
                </Col>
              </Row>

              <Row>
                <Col md={{offset: 2, span: 8}}>
                  <p>Powered by Stripe</p>
                </Col>
              </Row>
            </div>
          </form>
        <br />
      </div>
    );
  }
}

const mapStateToProps = state => ({ cart_errors: state.cart_errors })
export default injectStripe(connect(mapStateToProps)(StripeCheckout));
