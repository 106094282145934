import React from "react"
import { connect } from "react-redux"
import { Modal, Button, Row, Col } from "react-bootstrap"
import ReactPlayer from "react-player"

import GenreBadges from "components/GenreBadges"
import CartPlayButtons from "components/CartPlayButtons"
import { addCartItemRequest, cartItemIds } from "../actions"

class PresetModal extends React.Component {
  constructor(props) {
    super(props);
  }

  addPackToCart = (cart_item) => {
    this.props.dispatch(addCartItemRequest(cart_item.id));
  }

  render () {
    let inCart = this.props.cart_items_ids.includes(this.props.preset_pack.id);

    return (
      <Modal {...this.props} >
        <Modal.Body>
          <Row>
            <Col md={3}>
              <img src={this.props.preset_pack.image} width="100%" />

              <hr />

              <CartPlayButtons addToCart={this.addPackToCart} preset_pack={this.props.preset_pack} inCart={inCart} />
            </Col>

            <Col md={9}>
              {this.props.preset_pack.featured &&
                <div className="ribbon"><span>Featured</span></div>
              }

              {this.props.preset_pack.is_new &&
                <div className="ribbon"><span>New</span></div>
              }
              <h2>{this.props.preset_pack.name}</h2>

              <h5>
                <a href={"/preset_packs/manufacturers/"+this.props.preset_pack.manufacturer_slug}>
                  {this.props.preset_pack.manufacturer_name}
                </a>
              </h5>

              <GenreBadges genres={this.props.preset_pack.genres} />

              <hr />

              {this.props.preset_pack.has_youtube &&
                <ReactPlayer width="100%" url={this.props.preset_pack.youtube_link} />
              }

              <div dangerouslySetInnerHTML={{__html: this.props.preset_pack.page_text}} />

            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}


const mapStateToProps = state => ({
  cart_items_ids: cartItemIds(state.cart_items)
})

export default connect(mapStateToProps)(PresetModal)
